.aside {
    width: 308px;
    border-left: 1px solid #e0e0e0;
    padding: 16px;
    overflow: auto;
}

@media (max-width: 771px) {
    .aside {
        display: none;
    }
}

.aside-nav {
    padding: 64px 8px;
    position: relative;
}

.radio {
    width: 32px !important;
    height: 32px !important;
    background: #fafafa;
    border-radius: 100%;
    position: relative;
    z-index: 2;
}

.radio.active {
    background: #F2F4F7 !important;
}


.radio-box {
    background: #F5F5F5;

    width: 24px !important;
    height: 24px !important;
    position: relative;
    top: 4px;
    right: 4px;
    border-radius: 100%;
}

.radio-box.active {
    background: #E7EAF0;
}

.radio-checked {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #eee;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
}

.radio-checked.active {
    background: #2C4477;
}


.aside-nav ul li .title {
    padding-right: 8px;
    line-height: 20px;
    height: 20px;
    font-size: .875rem;
    color: #616161;
}

.aside-nav .title.active {
    color: #2c4477;
    font-weight: bold !important;
}


.title-container {
    position: relative;
    top: 8px;
    right: 8px;
    width: calc(100% - 48px);
}

.subtitle {
    color: #888;
    font-size: .75rem;
    line-height: 16px;
    padding-right: 8px;
    font-weight: normal;
}


.subtitle.active {
    color: #2C4477;
}


.aside-nav ul li {
    color: #3a558b;
    font-weight: bold;
    display: flex;
    /*margin-bottom: 48px;*/
    height: 100px;
}

.aside-nav ul li {
    display: flex;
}

.aside-nav ul {
    list-style: none;
    padding: 0;
}


.line {
    position: absolute;
    display: inline-table;
    width: 1px;
    height: 300px;
    background: #eeeeef;
    top: 80px;
    z-index: 1;
    right: 24px;
}




