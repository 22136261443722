body {
    direction: rtl;
    text-align: right;

}

.llllabel *{
    font-size: 13px !important;
}

.box {
    width: 99%;
    padding: 1mm;
    border: 1px solid;
    border-radius: 1px;
    border-bottom: 0;
}

.box:last-child {
    border-bottom: 1px solid;
}


page {
    text-align: right;
    background: white;
    display: block;
}

page[size="A6"][layout="landscape"] {
    padding: 1mm;
    width: 148mm;
    height: 105mm;
}

.col-1-3 {
    width: 33%;
}

.col-1-2 {
    width: 50%;
}

.col-3-4 {
    width: 100%;
}

.col-1-4 {
    width: 20%;
}

.row {
    display: flex;
}

img.top-logo {
    height: 6mm;
}

p {
    margin: 2mm;
    font-weight: bold;
    font-size: 13px;
}

select {
    font-size: 12px;
    font-weight: bold;
    border: none;
    background: #fff;
    font-weight: normal;
    font-family: iran-sans;
}

.row {
    line-height: 26px !important;
}

@media print {
    body, page {
        direction: rtl;
        float: right;
    }
}
