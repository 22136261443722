#home-page {
    background: #fff;

}

#home-page .header-title {
    color: #fff !important;

}

#home-page h1 {
    color: #fff;
}

#home-page .main-header-text {
    color: #222 !important;
}

#home-page text {
    color: #222 !important;
}

#home-page .text-muted {
    color: #333 !important;
}

#home-page .icon-box div {
    color: #333 !important;
}


#home-page .card-header {
    border:1px solid #ddd !important;
}

#home-page .nav-link {
    color: #333 !important;
}

main{
    padding: 0 !important;
}