.message-box {
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 16px;
    max-height: 400px;
    overflow: auto;
    scroll-behavior: smooth;
}

.user-message {
    background: #fff;
    color: #000;
    border-radius: 16px;
    padding: 8px;
    display: inline-block;
    float: right;
    border: 1px solid #eee;
    position: relative;
    border-top-right-radius: 0;
}



.admin-avatar {
    width: 44px;
}

.message-admin-type {
    display: flex;
    justify-content: left;
    direction: ltr;
}

.message-user-type {
    display: flex;
    justify-content: right;
    direction: rtl;
}

.type-box {
    border: 1px solid #eee;
    border-radius: 100%;
    text-align: center;
    width: 48px;
    align-items: center;
    display: flex;
    box-shadow: 0 0 4px 0 #eee;
    justify-content: center;
    height: 48px;
    position: relative;
}
.admin-message {
    background: #4444ff;
    color: #fff;
    border-radius: 16px;
    padding: 8px;
    display: inline-block;
    float: left;
    box-shadow: 0 0 4px 0 #4444ff;
    position: relative;
    border-top-left-radius: 0px;
    top: 12px;
    left: 4px;
}

.admin-message:after {
    content: " ";
    position: absolute;
    left: -7px;
    top: 4px;
    border-top: 8px solid transparent;
    border-right: 8px solid #4444ff;
    border-left: none;
    border-bottom: 8px solid transparent;
}
.user-message:after {
    content: " ";
    position: absolute;
    right: -8px;
    top: 4px;
    border-top: 8px solid transparent;
    border-left: 8px solid #aaa;
    border-right: none;
    border-bottom: 8px solid transparent;
}