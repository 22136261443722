:root {
    /* theme color */
    --main-color-one: #0761f6;
    --main-color-one-shadow: #0763f657;
    --main-color-one-hover: #0547b2;
    --main-color-one-color: #fff;
    --main-color-two: #ef473a;
    --main-color-two-shadow: #ef473a57;
    --main-color-two-hover: #ce2c20;
    --main-color-two-color: #fff;
    --main-color-three: #11c56b;
    --main-color-three-shadow: #11c56b57;
    --main-color-three-hover: #099f54;
    --main-color-three-color: #fff;
    --input-bg: #f1f1f1;
    --input-color: #515151;
    --input-redius: 25px;
    --input-border-size: 1px;
    --input-border-color: #ccc;
    --bg-site: #f4f5f9;
    --color-site: #515151;
    --font-size: '14px';
    --font-site: 'dana';
    --rasta-custom-color: #0761f6;
    /* shadow */
    --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-box: rgb(0 0 0 / 10%) 0px 0px 10px 0px !important;
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --shadow-inner: inset 0 2px 12px 4px rgb(0 0 0 / 5%);
    /* text */
    --text-muted: #565757;
    --text-muted-two: #929292;
    /* border */
    --border-muted: #ced4da;
}

/*@font-face {*/
/*    font-family: 'dana';*/
/*    src: url('../assets/font/dana/dana-fanum-regular.woff2') format('woff2');*/
/*}*/



p {
    line-height: 36px;
}

.tooltip {
    font-family: 'dana';
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-left: 7px;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-color: var(--main-color-one);
}

.content {
    padding: 20px 0;
}

.content-box {
    background-color: #fff;
    box-shadow: var(--shadow-box);
    padding: 20px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.content-box:nth-last-child(1) {
    margin-bottom: 0;
}

.font-en {
    font-family: 'dana' !important;
}

a {
    text-decoration: none;
    color: var(--color-site);
}

a:hover {
    color: var(--main-color-one);
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.def-color {
    color: var(--color-site) !important;
}

.py-20 {
    padding: 20px 0;
}

.menu-item-has-children a {
    position: relative;
}

.menu-item-has-children a:first-child::after {
    content: '\F282';
    font-family: 'bootstrap-icons';
    position: absolute;
    font-size: 10px;
    left: -15px;
    top: 50%;
    margin-right: 5px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.top-menu ul li>ul>li>a::after {
    opacity: 0;
    visibility: hidden;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-17 {
    font-size: 17px;
}

.font-18 {
    font-size: 18px;
}

.font-19 {
    font-size: 19px;
}

.font-20 {
    font-size: 20px;
}

.alert-success {
    --bs-alert-bg: #96e9c3;
}

.alert-info {
    --bs-alert-bg: #a5dde9;
}

.alert-primary {
    --bs-alert-color: #012252;
    --bs-alert-bg: #97bef9;
    --bs-alert-border-color: #97bef9;
}

.alert-danger {
    --bs-alert-color: #52060d;
    --bs-alert-bg: #eb9098;
    --bs-alert-border-color: #eb9098;
}

.alert-dark {
    --bs-alert-color: #f6f7f9;
    --bs-alert-bg: #1f1f20;
    --bs-alert-border-color: #212529;
}

.alert-secondary {
    --bs-alert-color: #282828;
    --bs-alert-bg: #9b9b9b;
    --bs-alert-border-color: #9b9b9b;
}

.alert-light {
    --bs-alert-color: #636464;
    --bs-alert-bg: #f1f1f14d;
    --bs-alert-border-color: #e3e0e0;
    box-shadow: var(--shadow-sm);
}

.text-overflow-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-overflow-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-overflow-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text-justify {
    text-align: justify;
}

.site-title {
    font-family: var(--font-site);
}

.span-primary {
    display: inline;
    background-color: #0967ff13;
    border-bottom: 1px dashed #0761f6;
    padding: 4px 7px;
    color: #0547b2;
}

.form-group {
    margin-bottom: 15px;
}

.main-color-one-color {
    color: var(--main-color-one) !important;
}

.progress-wrap {
    left: 20px;
}

.main-color-one-bg {
    border-color: var(--main-color-one);
    background-color: var(--main-color-one) !important;
    color: #fff !important;
    box-shadow: 0 5px 15px var(--main-color-one-shadow) !important;
}

.main-color-one-bg:hover {
    background-color: var(--main-color-one-hover) !important;
    color: #fff !important;
}

.main-color-two-color {
    color: var(--main-color-two) !important;
}

.main-color-two-bg {
    border-color: var(--main-color-two);
    background-color: var(--main-color-two) !important;
    color: #fff !important;
    box-shadow: 0 5px 15px var(--main-color-two-shadow) !important;
}

.main-color-two-bg:hover {
    background-color: var(--main-color-two-hover) !important;
    color: #fff !important;
}

.main-color-three-color {
    color: var(--main-color-three) !important;
}

.main-color-three-bg {
    border-color: var(--main-color-three);
    background-color: var(--main-color-three) !important;
    color: #fff !important;
    box-shadow: 0 5px 15px var(--main-color-three-shadow) !important;
}

.main-color-three-bg:hover {
    background-color: var(--main-color-three-hover) !important;
    color: #fff !important;
}

.mco-hover,
.mct-hover,
.mctt-hover {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.mco-hover:hover {
    color: var(--main-color-one-hover) !important;
}

.mct-hover:hover {
    color: var(--main-color-two-hover) !important;
}

.mctt-hover:hover {
    color: var(--main-color-three-hover) !important;
}

.shadow-sm {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.shadow-md {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.shadow-xl {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.shadow-2xl {
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.shadow-inner {
    box-shadow: inset 0 2px 12px 4px rgb(0 0 0 / 5%);
}

.shadow-box {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px !important;
}

.pointer {
    cursor: pointer;
}

.border-muted {
    border-color: var(--border-muted) !important;
}

.swiper-button-next,
.swiper-button-prev {
    box-shadow: var(--shadow-md);
    padding: 15px 15px;
    background: #f5f5f5;
    color: #515151;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    top: 50% !important;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 16px;
    color: #fff;
}

.swiper-button-next::after {
    position: absolute;
    left: 40%;
    transform: translateX(-40%);
    -webkit-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    -ms-transform: translateX(-40%);
    -o-transform: translateX(-40%);
}

.swiper-button-prev::after {
    position: absolute;
    right: 40%;
    transform: translateX(40%);
    -webkit-transform: translateX(40%);
    -moz-transform: translateX(40%);
    -ms-transform: translateX(40%);
    -o-transform: translateX(40%);
}

.swiper-button-prev {
    right: 0 !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.swiper-button-next {
    left: 0 !important;
}

.swiper-pagination-bullet-active {
    background: var(--main-color-one);
    width: 25px;
    height: 7px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.border-animate {
    /* you can change these variables to control the border */
    --border-color: var(--main-color-one);
    --border-width: 2px;
    --bottom-distance: 0px;
    /* you can increase this */
    color: #666;
    display: inline-block;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    margin: 5px 0;
}

.fromCenter {
    background-position: 50% calc(100% - var(--bottom-distance));
}

.fromRight {
    background-position: 100% calc(100% - var(--bottom-distance));
}

.fromLeft {
    background-position: 0 calc(100% - var(--bottom-distance))
}

.border-animate:hover {
    background-size: 100% var(--border-width);
}

.border-animate:focus {
    background-size: 100% var(--border-width);
}

/* STYLING SCROLLBAR */

::-webkit-scrollbar {
    width: 1vw;
    /* THIS WILL RESET THE DEFAULT SCORLLBAR STYLING */
}

/* TO STYLE THE SCROLLBAR TRACK */
::-webkit-scrollbar-track {
    background-color: #ffffff;
    /* THIS WILL SET THE COLOR OF THE SCROLLBAR TRACK */
}

/* TO STYLE THE SCROLLBAR THUMB */
::-webkit-scrollbar-thumb {
    background-color: var(--main-color-one);
    border: 0.3vw solid #ffffff;
    border-radius: 5vw;
    -webkit-border-radius: 5vw;
    -moz-border-radius: 5vw;
    -ms-border-radius: 5vw;
    -o-border-radius: 5vw;
}

/* start header */

.bg-primary-main {
    background-color: #0761f6 !important;
}

.bg-primary-light {
    background: var(--main-color-three);
    color: #fff;
}

.parent-top-menu {
    position: relative;
    width: 100%;
    height: 32px;
}

.top-menu {
    height: 70px;
    padding: 10px 0;
    box-shadow: inset 0 2px 12px 4px rgb(0 0 0 / 5%);
}

header {
    background: linear-gradient(68.6deg, rgb(57, 67, 248) 38.1%, rgb(192, 0, 255) 93.1%);
    position: relative;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.img-header {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
}

.photo-landing {
    max-width: 50%;
    margin: 0 auto;
    display: block;
}

.header-title {
    font-size: 60px;
}

.header-list {
    --bs-list-group-bg: transparent;
    --bs-list-group-color: #fff;
    --bs-list-group-border-color: transparent;
    text-align: right;
}


.img-page {
    max-height: 400px;
    overflow-y: hidden;
}


.page-card .card-body a {
    border-color: #f39a1f;
    color: #5f3302;
}

.page-card .card-body a:hover {
    background: #f39a1f;
    color: #5f3302;
}

.page-card {
    margin: 0 auto 20px auto;
    height: 500px;
    text-align: center;
}

.page-card .card-title {
    color: #333;
    font-size: 17px;
}

/* end header */

/* start wave  */

.waveWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    overflow: hidden;
}

.waveWrapperInner {
    position: absolute;
    width: 100%;
    height: calc(100% - 20vh);
    bottom: 0;
    overflow: hidden;
    /* background-image: linear-gradient(to top, #0099ff 20%, #005188 80%); */
}

.top {
    opacity: 0.5;
    z-index: 15;
}

.mid {
    opacity: 0.75;
    z-index: 10;
}

.bottom {
    z-index: 5;
}

.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat !important;
    background-position: 0 bottom;
    transform-origin: center bottom;
}

.waveTop {
    background-size: 50% 100px;
}

.waveTop {
    background-size: 50% 100px;
    animation: wave 3s;
    animation-delay: 1s;
}

@keyframes wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1);
    }

    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.50);
    }

    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1);
    }
}

.waveMid {
    background-size: 50% 120px;
    animation: wave 10s linear infinite;
}

.waveBottom {
    background-size: 50% 100px;
    animation: wave 15s linear infinite;
}

/* end wave */


/* start main */

main{
    padding: 50px 0;
}

.feature .boxs .box{
    height: 150px;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
}

.feature .boxs .box:hover{
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
}

.feature .boxs .box i{
    color: #ef473a;
}

.demo-box h4{
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

.demo-box:hover h4{
    color: #0761f6;
    font-weight: bold;
    border-bottom: 2px solid #0761f6 !important;
}

.demo-box .image {
    height: 400px;
    overflow: hidden;
}

.demo-box .image.blur img{
    filter: blur(10px);
    -webkit-filter: blur(10px);
}

.copy-right{
    padding: 20px 0;
    border-top: 2px solid #ddd;
}
/* end main */

@media only screen and (max-width:992px){

    header .title .image img{
        max-width: 100%;
    }

    header{
        height: 750px;
    }
}

@media only screen and (max-width:576px){

    header .logo .logo-item{
        max-width: 150px;
        margin: auto;
    }
}

@media only screen and (max-width:992px) {
    .img-header {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@media only screen and (max-width: 576px) {
    .header-title {
        font-size: 40px;
    }
}

@media only screen and (min-width: 1450px){
    .container{
        max-width: 1450px;
    }
}