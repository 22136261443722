.Toastify__toast-body {
    font-family: Vazirmatn !important
}

.row-small-padding {
    padding: 0 8px;

    > * {
        padding: 0 4px !important;
    }
}

.tac {
    text-align: center !important;
}

.ma {
    margin: auto;
}

.padding-table td {
    padding: 8px !important;
}

.cp {
    cursor: pointer;
}


.css-t3ipsp-control {
    height: auto !important;
    min-height: 48px !important;
}

.menu-item {
    direction: rtl !important;
    text-align: right !important;;
}

.menu-item *{
    direction: rtl !important;;
    text-align: right !important;;
}

.menu-item .ti {
    margin-right: 0 !important;
}