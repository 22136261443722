.product-card {
    display: flex;
    overflow: hidden;
    border-top: 1px solid #eee;
    padding: 8px;
    align-items: center;
}

.product-card img {
    width: 100%;
    aspect-ratio: 5/3;
    max-height: 100%;
}

.title {
    font-size: 14px;
    height: 48px;
    line-height: 24px;
    font-weight: normal !important;
    color: #020630;
}

.table th {
    font-weight: bold !important;
    color: #000 !important;
}

.table td {
    color: #070435;
}

.product-link a {
    color: inherit;
}

.product-link {
    border-bottom: 1px dashed #070435;
    padding-bottom: 4px;
    cursor: pointer;
}

.page-link {
    cursor: pointer;
}

.filter-select > div {
    margin: 0 0 0 8px;
    width: 100%;
    @media (max-width: 991px) {
        min-width: 33%;
        margin-top: 8px;
    }
}

.filter-select {
    display: flex;
    width: 80%;

    @media (max-width: 991px) {
        width: 100%;
        padding-right: 10px;
        padding-left: 2px;
    }
    justify-content: space-evenly;
}

.filter-container .search-input {
    @media (min-width: 992px) {
        margin-left: 8px;
        width: 380px;
    }
}


.filter-container {
    display: flex;
    @media (max-width: 991px) {
        display: block;
    }

    align-items: center;

    @media (min-width: 992px) {
        height: 80px;
    }
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-evenly;
}

.css-t3ipsp-control {
    height: 48px;

}

.css-13cymwt-control {
    height: 48px;
}

.page-link.active, .active > .page-link {
    z-index: 0;
}