
:root {
    --font-family: system-ui, sans-serif;
    --fs-400: clamp(1.13rem, calc(1.06rem + 0.33vw), 1.31rem);
    --clr-primary-200: rgb(255, 255, 255);
    --clr-accent-300: #685dd8;
    --clr-accent-400: #685dd8;
    --clr-shadow-200: rgb(225, 220, 220);
    --clr-shadow-300: rgb(92, 92, 92);
}

.input {
    padding-inline: 0.6em;
    /*max-width: 12.5rem;*/
    height: 2.5rem;
    border-radius: 8px;
    color: var(--clr-shadow-300);
    border: 1px solid var(--clr-shadow-200);
}

.input-wrapper input {
    width: 100%;
    color: #222;
    height: 48px;
    /*text-align: center;*/
    text-align: right;
}


.input-hint {
    color: #333;
    font-size: 12px;
    margin-top: 4px;
}


.input-wrapper {
    height: fit-content;
    position: relative;
    line-height: 14px;
    width: 100%;
    display: inline-block;
    height: 2.5rem;
    background-color: #fff;
}

.input-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: none;
    transition: all 160ms ease-in;
}

.input-text:hover {
    border-color: var(--clr-accent-300);
}

.input-text:focus {
    border-color: var(--clr-accent-400);
    border: 2px solid var(--clr-accent-400);
    transition: 0ms !important;
}

.input-text:focus ~ .input-label,
.input-text:not(:placeholder-shown) ~ .input-label,
.input-text:not(:placeholder-shown):focus ~ .input-label {
    top: -0.5rem;
    font-size: 0.8rem;
    right: 0.6rem;
    color: var(--clr-accent-400);
}

.input-text:not(:focus):not(:placeholder-shown) ~ .input-label {
    color: #666;
    background-color: var(--clr-primary-200);
}

.input-label {
    position: absolute;
    right: 0.4rem;
    top: 0.9rem;
    padding: 0 0.5rem;
    color: var(--clr-shadow-300);
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in, color 200ms ease-in;
    background-color: #fff;
}
