.content {
    position: relative;
    height: calc(100vh - 90px - 122px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 8px;
}


.header {
    color: #000;
    font-weight: bold;
    height: 90px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 24px;*/
    padding: 0 8px;

}


.header .time-container:hover {
    background: #bdbdbd;

}

.header .time-container {
    border-radius: 8px;
    width: 36px;
    height: 36px;
    text-align: center;
    cursor: pointer;
    transition: 300ms;
}

.header .ti-x {
    font-size: 24px;
    color: #616161;
    position: relative;
    top: 6px;
    font-weight: normal;
}

.header .time-container:hover .ti-x {
    color: #fff !important;
}


.radio-button {
    border-radius: 100%;
    border: 2px solid #aaa;
    width: 22px;
    height: 22px;
    display: inline-block;
    cursor: pointer;
    text-align: center;

}

.radio-button.active {
    border: 2px solid #7367F0;

}


.option-row {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 16px;
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checked {
    width: 14px;
    height: 14px;
    background: #7367F0;
    display: inline-block;
    border-radius: 100%;
    position: relative;
    margin-top: 2px;
}

.option-row .description {
    color: #616161;
    font-size: 12px;
    padding-left: 4px;
}

.option-row .title {
    font-weight: bold;
    font-size: 18px;
    color: #212121;
    height: 24px;
}

.footer {
    position: relative;
    height: 60px;
    padding: 36px 0;
    border-top: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
}