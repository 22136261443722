.dialog-cover {
    background: rgba(71, 71, 71, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 4000;
    opacity: 0;
    display: none;
    transition: 300ms;

}
@media (max-width: 767px) {
    .dialog-cover {
        transition: 400ms;
    }
}
.dialog-cover.active {
    display: block;
}
.dialog-cover.show {
    opacity: 1;
}
.dialog-cover .dialog {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    border-radius: 8px;
    transition: 100ms;
    max-width: 96%;
    max-height: 96%;
}
@media (max-width: 767px) {
    .dialog-cover .dialog {
        bottom: -100%;
        transition: 400ms;
        max-width: 100%;
        max-height: 80%;
    }
    .dialog-cover .dialog.active {
        bottom: 0;
        height: 600px;
        top: auto;
    }
}
@media (min-width: 768px) {
    .dialog-cover .dialog {
        width: 0;
        height: 0;
    }
    .dialog-cover .dialog.active {
        width: 600px;
        height: 600px;
    }
}
.dialog-cover .dialog .dialog-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 48px);
    width: calc(100%);
    padding: 0  16px;
}
.dialog-cover .dialog .dialog-header {
    /*height: 48px;*/
    /*border-bottom: 1px solid #eee;*/
    /*line-height: 54px;*/
    width: calc(100% - 16px);
    padding-top: 16px;
    margin: auto;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
}
.dialog-cover .dialog .dialog-header .close {
    padding-left: 8px;
    cursor: pointer;
}
