.main-logo {
    width: 100px;
    height: 100%;
}

.main-header-text {
    display: none;
}

.main-header-left button {
    background: #7367f0;
    border: 1px solid #7367f0;
    box-shadow: 0 0 7px 0 #4640a1;
}

.main-header-left {
    width: 100px;
    position: relative;
    top: 2px;
    left: 8px;
    display: flex;
    align-items: center;
}

@media (min-width: 700px) {
    .main-logo {
        width: 160px;
        height: 100%;
    }

    .main-header-text {
        display: block;
    }

    .main-header-left button {
        background: #7367f0;
        border: 1px solid #7367f0;
        box-shadow: 0 0 7px 0 #4640a1;
    }

    .main-header-left {
        width: 280px;
        position: relative;
        top: 2px;
        left: -30px;
        display: flex;
        align-items: center;
    }
}


.bg-primary-main {
    height: 100%;
}

.bg-primary-main {
    padding-top: 160px;
    padding-bottom: 100px;
}


@media (min-width: 992px) {
    .bg-primary-main {
        padding-top: 160px;
        padding-bottom: 200px;
    }

    .img-header {
        max-width: 80%;
        margin-top: 50px;
    }
}

@media (min-width: 1200px) {
    .bg-primary-main {
        padding-top: 240px;
        padding-bottom: 200px;
    }

}
