
.status-paid {
    color: #46c37b;
}

.status-on_delivery {
    color: #c158dc;
}

.status-canceled {
    color: #b0003a;
}

.status-delivered {
    color: #005b9f;
}
