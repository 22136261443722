.steps {
    height: 200px;
    position: relative;
    margin: 0;
    width: 100%;
    max-width: 100% ;
}

.dashed {
    border: 1px dashed #aaa;
    position: absolute;
    width: calc(100% - 100px);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    margin: auto auto;
    z-index: 1;
}

.icon-container {
    position: relative;
    top: 10px;
    z-index: 3;
    text-align: center;
    font-size: 13px;
    width: calc(100vw / 4);
}

.step-number {
    position: absolute;
    top: 0;
    background: #710aef;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    right: 0;
}


.step-image {
    position: absolute;
    width: 200px;
    right: -16px;
    height: 200px;
}

.steps-main {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: space-between;
}

.icon-box i {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 24px;
    height: 24px;
    font-size: 24px;
}

.icon-box {
    width: 48px;
    height: 48px;
    text-align: center;
    margin: auto !important;
    position: relative;
    top: 12px;
    background: rgba(173, 10, 221, 1);
    border-radius: 100%;
    color: #fff;
}

html[dir="rtl"] .ti {
    margin-right: auto;
}


.pr {
    position: relative;
}

.steps-main{
    margin: 0;
    max-width: 100%;
}



@media (min-width: 768px) {
    .steps {
        height: 300px;
    }

    .step-image {
        width: 300px;
        height: 300px;
    }

    .steps-main {
        height: 300px;
    }
    .dashed {
        width: calc(100% - 220px);
    }

}

@media (min-width: 992px) {
    .steps {
        height: 400px;

    }

    .steps div{
        font-size: 16px !important;
    }

    .dashed {
        width: calc(100% - 220px);
    }
    .step-image {
        width: 300px;
        height: 300px;
    }

    .steps-main {
        height: 300px;
    }


}

@media (min-width: 1030px) {
    .steps {
        height: 400px;
    }

    .step-image {
        width: 400px;
        height: 400px;
    }

    .steps-main {
        height: 400px;
    }
    .steps {
        margin: auto;
    }
    .step-image {
        right: 0;
    }
    .icon-box {
        width: 64px;
        height: 64px;
    }

    .icon-box i{
        width: 64px;
        height: 40px;
        font-size: 40px;
    }


    .dashed {
        width: calc(100% - 320px);
    }

}

@media (min-width: 1200px) {
    .steps {
        margin: auto;
        max-width: 1500px;
    }

    .dashed {
        width: calc(100% - 320px);
    }

    .step-image {
        right: 80px;
    }

    .step-image {
        width: 480px;
        height: 400px;
    }


    .steps-main{
        margin: auto;
        max-width: 1400px;
    }

    .icon-box {
        width: 64px;
        height: 64px;
    }

    .icon-box i{
        width: 64px;
        height: 40px;
        font-size: 40px;
    }

}