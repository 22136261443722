.x-container {
    background: #fff;
    margin-top: 1.3rem;
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    height: calc(100vh - 48px);
    padding: 0;
    width: 1100px !important;
    max-width: 96%;
}

.page-content {
    width: calc(100% - 308px);
    padding: 0 12px;
    overflow: auto;
}

@media (max-width: 771px) {
    .page-content {
        width: calc(100%);
    }
}

.btn-confirm-footer {
    width: 300px;
    height: 48px;
}

@media (max-width: 300px) {
    .btn-confirm-footer {
        width: 180px !important;
        height: 48px;
    }
}

@media (max-width: 330px) {
    .btn-confirm-footer {
        width: 200px !important;
        height: 48px;
    }
}

@media (max-width: 350px) {
    .btn-confirm-footer {
        width: 210px !important;
        height: 48px;
    }
}


@media (max-width: 400px) {
    .btn-confirm-footer {
        width: 250px;
        height: 48px;
    }
}


